<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow li-nav-header"
  [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass" 
      (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <ul class="nav navbar-nav flex-row">
        <!-- Remove position relative in responsive -->
        <li class="nav-item mobile-menu d-lg-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs11"
            (click)="toggleNavigation($event)">
            <i class="feather ft-menu font-large-1"></i></a></li>
        <li class="nav-item mr-auto"><a routerLink="['/orders']" class="navbar-brand"
            routerLink="/orders"><img class="brand-logo" alt="LIVE Law Image" 
              [src]="_themeSettingsConfig.brand.logo.value">
            <!-- <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3> -->
          </a>
          
        </li>
        <li class="nav-item d-none d-md-block nav-toggle">
          <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
            (click)="toggleFixMenu($event)">
            <i class="feather toggle-icon font-medium-3 white"
              [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}"></i>
          </a>
        </li>
        <li class="nav-item d-lg-none "><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <!-- New-->
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block"><a routerLink="" class="nav-link nav-link-expand"
              (click)="toggleFullScreen()" *ngIf="maximize === 'on'"><i class="ficon feather ft-maximize"></i></a></li>
          <li class="nav-item nav-search"><a routerLink="" class="nav-link nav-link-search" (click)="clickSearch()"
              *ngIf="search === 'on'"><i class="ficon feather ft-search"></i></a>
            <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}">
              <input class="input" type="text" placeholder="Explore Modern...">
            </div>
          </li>

        </ul>
        <ul class="nav navbar-nav float-right" >
          <li class="dropdown-language nav-item" ngbDropdown >
            <a class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
              <span class="li-title white" *ngIf="currentUser.group == groups.ExternalUser">{{currentUser.companyName}}</span>
            </a>
          </li>
          <li class="dropdown-user nav-item" ngbDropdown [hidden]="wideScreen">
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser.displayName"
                class="mr-1 li-user-name text-bold-700">Place Order</span>
              <span>
                <i class="zmdi zmdi-plus-circle zmdi-hc-2x"></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <!-- <a class="dropdown-item" routerLink="['/user/user-profile']"><i class="feather ft-user"></i> Edit
                Profile </a>
              <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" routerLink="/orders/copy" routerLinkActive="active-link" class="menu-item"><i class="zmdi zmdi-copy"></i>&nbsp;Copy</a>
              <a class="dropdown-item" routerLink="/orders/print" routerLinkActive="active-link"><i class="zmdi zmdi-print"></i>&nbsp;Print</a>
              <a class="dropdown-item" routerLink="/orders/scan" routerLinkActive="active-link"><i class="zmdi zmdi-scanner"></i>&nbsp;Scan</a>
              <a class="dropdown-item" routerLink="/orders/court-appeal" routerLinkActive="active-link"><i class="zmdi zmdi-repeat"></i>&nbsp;Appeal & Court Book</a>
              <a class="dropdown-item" routerLink="/orders/court-copy" routerLinkActive="active-link"><i class="zmdi zmdi-balance"></i>&nbsp;Court Copy</a>
              <a class="dropdown-item" routerLink="/orders/multimedia-duplication" routerLinkActive="active-link"><i class="zmdi zmdi-collection-folder-image"></i>&nbsp;Multimedia Duplication</a>
              <a class="dropdown-item" routerLink="/orders/eFiling" routerLinkActive="active-link"><i class="zmdi zmdi-folder"></i>&nbsp;eFiling</a>
              <a class="dropdown-item" routerLink="/orders/eDiscovery" routerLinkActive="active-link"><i class="zmdi zmdi-explicit"></i>&nbsp;eDiscovery</a>
              <a class="dropdown-item" routerLink="/orders/hardcopy-processing" routerLinkActive="active-link"><i class="zmdi zmdi-collection-text"></i>&nbsp;Hard Copy Processing</a>
      
            </div>
          </li>
          <li class="dropdown-notification nav-item dropdown" ngbDropdown *ngIf ="notification === 'on'">
            <a class="nav-link nav-link-label" ngbDropdownToggle>
              <i class="ficon feather ft-bell"></i>
              <span class="badge badge-pill badge-danger badge-up badge-glow">{{notifications.length + broadcast.length}}</span>
            </a>
            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
              <li class="dropdown-menu-header">
                <h6 class="dropdown-header m-0"><span class="grey darken-2">Notifications</span></h6><span
                  class="notification-tag badge badge-default badge-danger float-right m-0">1 New</span>
              </li>
              <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y" fxFlex="auto"
                [perfectScrollbar]="config" *ngFor="let item of notifications">
                <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left align-self-center"><i
                        class="feather ft-alert-circle icon-bg-circle bg-red bg-darken-1"></i></div>
                    <div class="media-body">
                      <h6 class="media-heading red darken-1">{{item.title}}</h6>
                      <p class="notification-text font-small-3 text-muted">{{item.description}}</p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y" fxFlex="auto"
                [perfectScrollbar]="config" *ngFor="let item of broadcast">
                <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left align-self-center"><i
                        class="feather ft-download-cloud icon-bg-circle bg-red bg-darken-1"></i></div>
                    <div class="media-body">
                      <h6 class="media-heading red darken-1">{{item.title}}</h6>
                      <p class="notification-text font-small-3 text-muted">{{item.description}}</p>
                        <button class="btn btn-sm li-primary-button" (click)="reloadPage()" *ngIf="item.releaseNotification">Reload Now</button>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer" *ngIf="notifications.length > 0"><a class="dropdown-item text-muted text-center"
                (click)="markAsRead()">Mark as read</a></li>
              </ul>
          </li>          
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
                <span *ngIf="currentUser.displayName"
                class="mr-1 user-name li-user-name">Hello {{currentUser.displayName}}</span>
              <span>
                <span class="avatar">
                  <img *ngIf="currentUser.role != roles.Staff" src="../../../assets/images/portrait/small/power-user-icon.png"
                  alt="avatar">
                  <img *ngIf="currentUser.role == roles.Staff" src="../../../assets/images/portrait/small/normal-user-icon.png"
                  alt="avatar">
                </span>
  
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <!-- <a class="dropdown-item" routerLink="/users/my-profile" [queryParams]="{id: myUID}"><i class="feather ft-user"></i> My
                Profile </a>
              <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" (click)="logout()"><i class="feather ft-power"></i> Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- New-->
  </div>
</nav>

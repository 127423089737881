import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MobileComponent } from './content/mobile/mobile.component';

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'change-password', component: ResetPasswordComponent },
      { path: '', component: LoginComponent },
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'orders', loadChildren: () => import('./content/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
      { path: 'rate-card', loadChildren: () => import('./content/rate-card/rate-card.module').then(m => m.RateCardModule), canActivate: [AuthGuard] },
      { path: 'customers', loadChildren: () => import('./content/customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthGuard], canActivateChild:[AuthGuard] },
      { path: 'users', loadChildren: () => import('./content/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard], canActivateChild:[AuthGuard] },
      { path: 'tv-dashboard', loadChildren: () => import('./content/tv-dashboard/tv-dashboard.module').then(m => m.TvDashboardModule), canActivate: [AuthGuard] },
      { path: 'mobile', loadChildren: () => import('./content/mobile/mobile.module').then(m => m.MobileModule), canActivate: [AuthGuard] },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'orders' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top'});

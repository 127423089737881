// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,
  type: "Test",
  appVersion: require('../../package.json').version + '-Test',
  firebase: {
    apiKey: "AIzaSyAwUlEPwnbtESFlhldOPSj0Lfye6nrKqqg",
    authDomain: "test-live-lawimage.firebaseapp.com",
    projectId: "test-live-lawimage",
    storageBucket: "test-live-lawimage.appspot.com",
    messagingSenderId: "765255330450",
    appId: "1:765255330450:web:902f1bc93821b824b9944b"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

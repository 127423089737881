import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { IpServiceService } from '../_services/ip-service/ip-service.service'; 

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    errorMessage = '';
    successMessage = '';
    ipAddress: string;
    userAgent: string;
    captchaSuccess: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private ip:IpServiceService
        ) { }

    async ngOnInit() {
        this.registerForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            email: ['', Validators.required],
            businessName: ['', Validators.required],
            businessContactNumber: ['', [Validators.required, Validators.pattern(new RegExp("[0-9 ]{8}"))]],
            businessState: ['', Validators.required],
            recaptchaReactive: [null, Validators.required],
            userAgent: [],
            ipAddress: [this.getIP()],
            captchaResponse: [],
            captchaError: []
        });
        
        await this.getIP();
        if (navigator) {
            this.f.userAgent.setValue(navigator.userAgent);
        }
    }

    public resolved(captchaResponse: string) {
        this.f.captchaResponse.setValue(captchaResponse);
        this.captchaSuccess = true;
    }

    public onError(errorDetails: any[]) {
        this.f.captchaError.setValue(errorDetails);
        this.captchaSuccess = false;
    }

    async getIP()  
    {  
      await this.ip.getIPAddress().then(res=>{
        this.f.ipAddress.setValue(res.ip);
      })
    }         

    states = [{
        id: "NSW"
    }, {
        id: "VIC"
    }, {
        id: "WA"
    }, {
        id: "QLD"
    }]
    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }


    tryRequestAccess() {
        this.submitted = true;

        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;

        this.authService.doRequestAccess(this.registerForm.value)
        .then(res => {
            this.loading = false;
          this.errorMessage = '';
          this.alertService.success('Your request has been recieved. Thank you.', true);
          this.router.navigate(['/login']);
        }, err => {
          this.loading = false;
          this.alertService.error(err.message);
        });
      }

}

<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter && siteSettings">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block footer-text">&copy; {{siteSettings.copyright_year}} | {{siteSettings.copyright_text}} | v{{currentApplicationVersion}}</span><span *ngIf="siteSettings.environment != 'PROD'">{{siteSettings.environment}} Environment</span>
      <span
      class="float-md-right d-block d-md-inline-block d-none d-lg-block footer-text">
        <a [href]="siteSettings.terms_url" target="_blank">Terms & Conditions</a> | 
        <a [href]="siteSettings.privacy_url" target="_blank">Privacy Policy</a> |
        <a [href]="siteSettings.data_protection_url" target="_blank">Data Protection</a>
      <span id="scroll-top"></span></span></p>
</footer>

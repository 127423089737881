// Default menu settings configurations

import { GroupPolicies, Groups, Policy, PolicyScore, Roles } from "src/app/_models/constants/setting";

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  link: string;
  policy: Policy;
  mail: boolean;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      {
        title: 'Changelog',
        icon: 'la-file',
        page: '/changelog',
        badge: { type: 'badge-danger', value: '2.2' }
      },
      {
        title: 'Templates',
        icon: 'la-television',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Horizontal',
              page: 'null'
            },
            {
              title: 'Vertical',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Raise Support',
        icon: 'la-support',
        page: 'https://pixinvent.ticksy.com/',
        isExternalLink: true
      },
      {
        title: 'Documentaion',
        icon: 'la-text-height',
        page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
        isExternalLink: true,
      }
    ]
  },
  vertical_menu: {
    items: [
      {//Home
        title: 'Home',
        icon: 'la-home',
        page: '/orders',
        policy: {
          score: PolicyScore.Staff,
          group: Groups.Both
        }
      },
      {//Clients
        title: 'Clients',
        icon: 'la-industry',
        page: '/customers',
        policy: {
          group: Groups.InternalUser,
          score: PolicyScore.Accountant,
        }
      },
      {//Rate Cards
        title: 'Rate Cards',
        icon: 'la-file-invoice-dollar',
        page: '/rate-card',
        policy: {
          group: Groups.InternalUser,
          score: PolicyScore.Accountant,
        }
      },
      {//Order Search
        title: 'Order Search',
        icon: 'la-search',
        page: '/orders/search',
        policy: {
          score: PolicyScore.Staff,
          group: Groups.Both
        }
      },
      {
        title: 'Our Staff',
        icon: 'la-users',
        page: '/users/internal-users',
        policy: {
          group: Groups.InternalUser,
          score: PolicyScore.Admin
        }
      },
      // {//Quotes
      //   title: 'Quote',
      //   icon: 'la-quora',
      //   page: '#',
      //   policy: {
      //     score: PolicyScore.Staff,
      //     group: Groups.Both
      //   }
      // },      
      // {//Users
      //   title: 'Users',
      //   icon: 'la-users',
      //   page: 'null',
      //   policy: {
      //     groupPolicy: GroupPolicies.InternalSuper,
      //     score: PolicyScore.Super
      //   },
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Internal Users',
      //         page: '/users/internal-users'
      //       },
      //       {
      //         title: 'External Users',
      //         page: '/users/external-users'
      //       }
      //     ]
      //   }

      // },
      // {//Invoices
      //   title: 'Invoices',
      //   icon: 'la-dollar',
      //   page: '/invoices',
      //   policy: {
      //     group: Groups.InternalUser,
      //     score: PolicyScore.Accountant
      //   }
      // },
      {//Client Users
        title: 'Manage Team',
        icon: 'la-users',
        page: '/users/my-staff',
        policy: {
          group: Groups.ExternalUser,
          score: PolicyScore.Admin
        }
      },
      {//Client Users
        title: 'TV Dashboard',
        icon: 'la-tv',
        page: '/tv-dashboard',
        policy: {
          group: Groups.InternalUser,
          score: PolicyScore.Admin
        }
      },
      {//Client Users
        title: 'Mobile',
        icon: 'la-mobile',
        page: '/mobile',
        policy: {
          group: Groups.InternalUser,
          score: PolicyScore.Staff
        }
      },
      { section: 'SUPPORT', icon: 'la-ellipsis-h' },
      {
        title: '1300 law image (529 462)',
        icon: 'la-support',
        link: 'tel:1300529462',
        mail: true,
        policy: {
          group: Groups.Both,
          score: PolicyScore.Staff
        }
      },
      {
        title: 'helpdesk@lawimage.com',
        icon: 'la-support',
        link: 'mailto:helpdesk@lawimage.com',
        mail: true,
        policy: {
          group: Groups.Both,
          score: PolicyScore.Staff
        }
      },
    ]
  }

};






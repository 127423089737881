import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private firestore: AngularFirestore,) { }

  public getCommonSettings() {
    return this.firestore.collection('siteConfig').doc('settings').get();

  }

  public subsribeToNotifications() {
    return this.firestore.collection('siteConfig').doc('releaseNotification').valueChanges();
  }

}

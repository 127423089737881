<div class="app-content content">
        <div class="content-wrapper">
          <div class="content-header row mb-1">
          </div>
          <div class="content-body"><section class="flexbox-container">
      <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-2 p-0">
              <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                  <div class="card-header border-0 pb-0">
                      <div class="card-title text-center">
                          <img src="../../assets/custom/images/li-logo.png" alt="LIVE Law Image" width="60%">
                      </div>
                  </div>
                  <div class="card-content">
                      <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1"></p>
                      <div class="card-body">
                          <form class="form-horizontal" [formGroup]="registerForm" (ngSubmit)="tryRequestAccess()">
                              <fieldset class="form-group position-relative">
                                  <input type="text" formControlName="fullName" class="form-control" [ngClass]="{ 'is-invalid': f.fullName.errors }"
                                    placeholder="Full Name">
                                  <!-- <div class="form-control-position">
                                      <i class="feather ft-user"></i>
                                  </div> -->
                                  <div *ngIf="f.fullName.errors" class="invalid-feedback">
                                        <div *ngIf="f.fullName.errors.required">Full Name is required</div>
                                    </div>
                              </fieldset>
                              <fieldset class="form-group position-relative">
                                  <input type="email" formControlName="email" class="form-control" email [ngClass]="{ 'is-invalid': f.email.errors }"
                                  placeholder="Your Email Address" required>
                                  <!-- <div class="form-control-position">
                                      <i class="feather ft-mail"></i>
                                  </div> -->
                                  <div *ngIf="f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                    </div>
                              </fieldset>
                              <fieldset class="form-group position-relative">
                                  <input type="text" formControlName="businessName" class="form-control" [ngClass]="{ 'is-invalid': f.businessName.errors }"
                                    placeholder="Your Business Name" required>
                                  <!-- <div class="form-control-position">
                                      <i class="la la-building"></i>
                                  </div> -->
                                  <div *ngIf="f.businessName.errors" class="invalid-feedback">
                                        <div *ngIf="f.businessName.errors.required">Business Name is required</div>
                                    </div>
                              </fieldset>
                              <fieldset class="form-group position-relative">
                                <input type="text" formControlName="businessContactNumber" class="form-control" [ngClass]="{ 'is-invalid': f.businessContactNumber.errors }"
                                placeholder="Your Business Contact Number" required maxlength="14" mask="(00) 0000 0000">
                                <!-- <div class="form-control-position">
                                    <i class="la la-phone"></i>
                                </div> -->
                                <div *ngIf="f.businessContactNumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.businessContactNumber.errors.required">Business Contact Number is required</div>
                                    <div *ngIf="f.businessContactNumber.errors.pattern">Valid Contact Number is required</div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group position-relative">
                                <label for="contry">Choose State</label>
                                <select class="form-control" id="state" formControlName="businessState" [ngClass]="{ 'is-invalid': f.businessContactNumber.errors }">
                                    <option *ngFor="let state of states" [value]="state.id">{{state.id}}</option>
                                </select>
                                <div *ngIf="f.businessState.errors" class="invalid-feedback">
                                      <div *ngIf="f.businessState.errors.required">Business State  is required</div>
                                  </div>
                            </fieldset>
                            <div style="transform:scale(0.9);transform-origin:0;">
                                <re-captcha
                                    formControlName="recaptchaReactive"
                                    (resolved)="resolved($event)"
                                    (error)="onError($event)"
                                    errorMode="handled"
                                    siteKey="6LcxZf8ZAAAAAOjHR9iFftWabhCMcfduG77gtlRg"
                                ></re-captcha>
                            </div>

                              <button type="submit" class="btn li-red-button btn-block btn-square" [disabled]="!registerForm.valid">
                                  <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                                <i class="feather ft-user" *ngIf="!loading"></i> Request Access
                              </button>
                          </form>
                      </div>
                      <div class="card-body">
                          <a [routerLink]="['/login']" class="btn li-black-button btn-block btn-square"><i class="feather ft-unlock"></i> Login</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

          </div>
        </div>
      </div>

<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
      </div>
      <div class="content-body"><section class="flexbox-container">
  <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="col-md-4 col-10 box-shadow-2 p-0">
          <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
              <div class="card-header border-0">
                  <div class="card-title text-center">
                      <img src="../../assets/custom/images/li-logo.png" alt="LIVE Law Image" width="60%">
                  </div>
              </div>
              <div class="card-content" >
                  <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1"></p>
                  <div class="card-body">
                      <form class="form-horizontal" [formGroup]="passwordResetForm" (ngSubmit)="handlePasswordResetClick()">
                          <fieldset class="form-group position-relative has-icon-left">
                              <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': f.email.errors }"
                               id="user-name" placeholder="Your Username" required>
                              <div class="form-control-position">
                                  <i class="feather ft-user"></i>
                              </div>
                              <div *ngIf="f.email.errors" class="invalid-feedback">
                                  <div *ngIf="f.email.errors.required">Username is required</div>
                              </div>
                          </fieldset>
                          <fieldset class="form-group position-relative has-icon-left" *ngIf="resetFlow">
                            <input type="password" placeholder="Enter New Password" #pwdelement pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$'
                                formControlName="password" class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': f.password.errors }" />
                            <div class="form-control-position">
                                <i class="la la-key"></i>
                            </div>
                            <div class="col-6 form-check custom-control custom-checkbox"  >
                                <input type="checkbox" #togglepassword id="togglepassword" class="custom-control-input custom-checkbox" (click)="togglePassword()" />
                                  <label for="togglepassword" id="passwordlabel" #passwordlabel class="custom-control-label">Show Password</label>
                            </div>
                            <div *ngIf="f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">New Password is required</div>
                                <div *ngIf="f.password.errors.pattern">Password must be a minimum of 8 characters and contain at least one letter and one number.</div>
                            </div>

                        </fieldset>

                          <button type="submit" class="btn li-red-button btn-block btn-square" *ngIf="!resetFlow" [disabled]="f.email.invalid">
                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Recover Password
                          </button>
                          <button type="submit" class="btn li-red-button btn-block btn-square" *ngIf="resetFlow">
                            <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                            <i class="feather ft-unlock" *ngIf="!loading"></i> Reset Password
                          </button>
                      </form>
                  </div>
                  <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1"></p>
                  <div class="card-body">
                      <a [routerLink]="['/login']" class="btn li-primary-button btn-block btn-square"><i class="feather ft-user"></i> Login</a>
                  </div>
              </div>
            </div>

      </div>
  </div>
  </section>
  
      </div>
    </div>
  </div>
  
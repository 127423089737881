<div class="row bread">
  <div class="card-header li-primary-button content-header-left col-12 mb-2 breadcrumb-new" *ngIf="breadcrumb">
    <h3 class="content-header-title mb-0 d-inline-block">{{breadcrumb.mainlabel}}</h3>
    <div class="row breadcrumbs-top d-inline-block">
      <div class="breadcrumb-wrapper col-12">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngFor="let link of breadcrumb.links">
            <a *ngIf="link.isLink" routerLink="{{link.link}}">{{link.name}}</a>
            <span *ngIf="!link.isLink">{{link.name}}</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

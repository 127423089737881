import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UsersService } from '../_services/users/users.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
import { ErrorMessages } from '../_helpers/app.errorMessages';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn li-button li-primary-button confirm-button',
    cancelButton: 'btn btn-secondary li-button cancel-button',
    title: 'li-title',
    text: 'li-body'
  },
  buttonsStyling: false
})

const customswalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn li-button li-primary-button confirm-button customWidth',
    cancelButton: 'btn btn-secondary li-button cancel-button',
    title: 'li-title',
    text: 'li-body'
  },
  buttonsStyling: false
})

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
 })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    debug = false;

    constructor(
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private afAuth: AngularFireAuth,
        private afDB: AngularFireDatabase) {
            let callback = null;
            let metadataRef = null;
            afAuth.onAuthStateChanged(user => {
              // Remove previous listener.
              if (callback) {
                metadataRef.off('value', callback);
              }
              // On user login add new listener.
              if (user) {
                // Check if refresh is required.
                metadataRef = afDB.database.ref('metadata/' + user.uid + '/refreshTime');
                callback = (snapshot) => {
                  // Force refresh to pick up the latest custom claims changes.
                  // Note this is always triggered on first call. Further optimization could be
                  // added to avoid the initial trigger when the token is issued and already contains
                  // the latest claims.
                  user.getIdToken(true).then(()=>{
                    let localPolicy;
                    if (this.authService.getLoggedInUser().policy)
                         localPolicy = this.authService.getLoggedInUser().policy;
                    else
                      return;
                    console.log('localpolicy', localPolicy);
                    this.authService.getLoggendInUserGroupPolicy().then(policy =>{
                      console.log('auth policy', policy);
                      if (localPolicy.score != policy.score){
                        swalWithBootstrapButtons.fire({
                          title: ErrorMessages.ERROR_MESSAGE_TITLE_SORRY,
                          text: ErrorMessages.ERROR_POLICY_CHANGED,
                          icon: 'warning',
                          showCancelButton: false,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.authService.doLogout();
                            this.router.navigate(['/login']);
                          }
                        })
                      }
                    })
          
                  });
                };
                // Subscribe new listener to changes on that node.
                metadataRef.on('value', callback);
              }
            });            
         }

    ngOnInit() {
      this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberme: []
            //email: ['veronique.moll-vignes@minterellison.com', Validators.required],
            // email: ['rachel.leigh@curwoods.com.au', Validators.required],
            // email: ['feroz@loudweb.com.au', Validators.required],
            // password: ['rWLkWAkBgidYa47', Validators.required]
            // password: ['hellokitty', Validators.required]
        });

        if (this.debug) {
          this.loginForm.get('email').setValue('feroz@loudweb.com.au');
          this.loginForm.get('password').setValue('rWLkWAkBgidYa47');  
        }

    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        const value = {
            email: this.f.email.value,
            password: this.f.password.value
        };
        this.authService.doLogin(value)
            .then(async res => {
                console.log('login', res);
                if (res && res.policy.group == undefined) {
                    console.log('login', 'policy missing');
                    customswalWithBootstrapButtons.fire({
                        title: ErrorMessages.ERROR_MESSAGE_TITLE_ACCESS_DENIED,
                        text: ErrorMessages.ERROR_PERMISSION_DENIED_CONTACT_SUPPORT,
                        showConfirmButton: false 
                      });
                
                        this.submitted = false;
                        this.authService.doLogout();
                } else { 
                    localStorage.removeItem('currentLayoutStyle');
                    let returnUrl = '/orders';
                    if (this.returnUrl) {
                        returnUrl = this.returnUrl;
                    }
                    this.router.navigate([returnUrl]);        
                }
                
            }, err => {
                this.submitted = false;
                this.alertService.error(ErrorMessages.ERROR_USER_NOT_FOUND);
            });
    }
}

export class ErrorMessages {
    public static ERROR_USER_NOT_FOUND = "User not found. Please contact support.";
    public static ERROR_FETCHING_USER_DATA = "Error fetching user data.";
    public static ERROR_FIRESTORE_PERMISSION_DENIED = "permission-denied";
    public static ERROR_PERMISSION_DENIED_CONTACT_SUPPORT = "Looks like your permissions haven't been setup correctly. Please contact Law Image support on 1300 529 462.";
    public static ERROR_MESSAGE_TITLE_ACCESS_DENIED = "Access denied";
    public static ERROR_MESSAGE_TITLE_SORRY = "Sorry!";

    public static ERROR_ORDER_CREATION_FAILED = "Failed to create the order. Please try again."
    public static ERROR_POLICY_CHANGED = "Your account access has been changed by the administrator. Please login again or contact Law Image support on 1300 529 462."

  //Constants for SWAL messages
  public static SWAL_TITLE_SUCCESS_MESSAGE = "Success";
  public static SWAL_TITLE_OOPS = "Oops!";
  public static SWAL_TITLE_ERROR_MESSAGE = "Error";
  public static SWAL_TITLE_CONFIRMATION_MESSAGE = "Are you sure?";
  public static SWAL_TEXT_USER_DEACTIVATE_CONFIRMATION = "Blocking this user will prevent them from logging in to LIVE.";
  public static SWAL_TEXT_USER_ACTIVATE_CONFIRMATION = "You are re-activating this user to login to LIVE.";
  public static SWAL_CONFIRMATION_CONFIRM_BUTTON_TEXT = "Yes, continue.";
  public static SWAL_CONFIRMATION_CANCEL_BUTTON_TEXT = "No.";
  public static SWAL_ICON_WARNING = "warning";
  public static SWAL_TEXT_USER_MAKE_ADMIN_CONFIRMATION = "Making this user an Admin will give them additional privileges in LIVE.";
  public static SWAL_TEXT_USER_MAKE_ACCOUTANT_CONFIRMATION = "Making this user an Accountant will give them additional privileges in LIVE.";
  public static SWAL_TEXT_USER_REVOKE_ACCOUTANT_CONFIRMATION = "Removing Accountant privilege will make this user a Staff.";
  public static SWAL_TEXT_USER_MAKE_NATIONAL_ADMIN_CONFIRMATION = "Making this user a National Admin will give them additional privileges in LIVE.";
  public static SWAL_TEXT_USER_REMOVE_ADMIN_CONFIRMATION = "Removing Admin privilege will make this user a Staff.";
  public static SWAL_TEXT_USER_RESET_PASSWORD = "A password reset link will be sent to this user.";

  public static SWAL_TEXT_UNKNOWN_ERROR = "Something went wrong.";

  public static USER_ROLE_CHANGED_MESSAGE = "User role changed successfully.";
  public static USER_ADDED_MESSAGE = "User has been added successfully.";

  public static SYSTEM_ERROR_MESSAGE = "System error occured.";

  public static PRICE_UPDATED_MESSAGE = "Price updated successfully.";
  public static PRICE_INVALID_MESSAGE = "Invalid price entered. Please enter a valid price.";

  public static NEW_ORDER_DATE_MESSAGE = "You are about to set a new order due date.";
  public static ORDER_PROCES_OFFSITE_MESSAGE = "You are about to change the process offsite setting for this order.";

  public static MYOB_ERROR = "Unable to generate invoice. Please check MYOB before auto-generating another invoice.";

  public static INVOICE_GENERATE_SUCCESS = "Invoice generated successfully.";
  public static INVOICE_GENERATE_WAIT = "Generating invoice, please wait.";
  public static INVOICE_FETCH_WAIT = "Fetching & linking invoice, please wait.";
  public static INVOICE_SYNC_SUCCESS = "Invoice synced successfully.";

  public static MYOB_SYNC = "This will sync the data from MYOB and update LIVE.";

  public static INVOICE_APPROVAL_STATUS_UPDATED = "Invoice approval status updated.";
  public static INVOICE_CUSTOMER_VISIBLE = "This will make the invoice visible to the customer.";
  public static INVOICE_AUTO_GENERATE = "This will auto-generate an invoice in MYOB.";
  public static INVOICE_DELETED = "Invoice deleted successfully.";
  public static INVOICE_REMOVED = "This invoice will be removed.";

  public static SITE_SETTINGS_UPDATED_TITLE = "A new version of LIVE has been released."
  public static SITE_SETTINGS_UPDATED_MESSAGE = "Do you want to auto-refresh this page now?"
  public static SITE_SETTINGS_UPDATED_CONFIRM = "Yes, refresh now."
  public static SITE_SETTINGS_UPDATED_CANCEL = "No, I will do it later."
  
  public static LABEL_PRINTER_NOT_FOUND = "No supported label printers were found."

}

  
 
export enum AppSettings {
    ToastTimeOut= 800,
}

export enum GroupPolicies {
    InternalStaff = "InternalUser.Staff",
    ExternalStaff = "ExternalUser.Staff",
    InternalAdmin = "InternalUser.Admin",
    ExternalAdmin = "ExternalUser.Admin",
    ExternalNationalAdmin = "ExternalUser.NationalAdmin",
    InternalAccountant = "InternalUser.Accountant",
    InternalSuper = "InternalUser.Super",

}

export enum Groups {
    InternalUser = "InternalUser",
    ExternalUser = "ExternalUser",
    Both = "Both"
}

export enum Roles {
    Staff = "Staff",
    Admin = "Admin",
    NationalAdmin = "NationalAdmin",
    Accountant = "Accountant",
    Super = "Super"
}

export enum PolicyScore {
    Staff = 1,
    Admin = 10,
    NationalAdmin = 20,
    Accountant = 20,
    Super = 30
}

export interface Policy {
    group?: Groups,
    role?: Roles,
    groupPolicy?: GroupPolicies,
    score?: number,
    companyId?: string
}